<template>
  <div class="error-page">
    <div class="content">
      <div class="left-side">
        <img :src="getError(error?.statusCode!)!.image" alt="error" />
      </div>
      <div class="right-side">
        <h1>{{ getError(error?.statusCode!)!.title }}</h1>
        <p>{{ getError(error?.statusCode!)!.text1 }}</p>
        <p>
          {{ getError(error?.statusCode!)!.text2 }}
          <el-button
            v-if="getError(error?.statusCode!)!.link"
            type="primary"
            link
            @click="redirectToHome"
          >
            {{ getError(error?.statusCode!)!.link }}
          </el-button>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import err400 from "~/assets/img/errors/400.png";
import err401 from "~/assets/img/errors/401.png";
import err403 from "~/assets/img/errors/403.png";
import err404 from "~/assets/img/errors/404.png";
import err500 from "~/assets/img/errors/500.png";

const error = useError();
const { t } = useI18n();

const errorsArray = [
  {
    id: 400,
    image: err400,
    title: t("errors.crash_errors.400.title"),
    text1: t("errors.crash_errors.400.text1"),
    text2: t("errors.crash_errors.400.text2"),
  },
  {
    id: 401,
    image: err401,
    title: t("errors.crash_errors.401.title"),
    text1: t("errors.crash_errors.401.text1"),
    text2: t("errors.crash_errors.401.text2"),
  },
  {
    id: 403,
    image: err403,
    title: t("errors.crash_errors.403.title"),
    text1: t("errors.crash_errors.403.text1"),
    text2: t("errors.crash_errors.403.text2"),
    link: t("profile.p_try"),
  },
  {
    id: 404,
    image: err404,
    title: t("errors.crash_errors.404.title"),
    text1: t("errors.crash_errors.404.text1"),
    text2: t("errors.crash_errors.404.text2"),
  },
  {
    id: 500,
    image: err500,
    title: t("errors.crash_errors.500.title"),
    text1: t("errors.crash_errors.500.text1"),
    text2: "",
  },
];

const getError = (error_id: number) => {
  return errorsArray.find((error) => error.id === error_id);
};

const redirectToHome = () => {
  const localePath = useLocalePath();
  const route = localePath("/");
  navigateTo(route);
};
</script>

<style scoped lang="scss">
.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: white;
  .right-side {
    width: 420px;
  }
  .content {
    display: flex;
    text-align: center;
    align-items: center;
    color: #000;

    h1 {
      font-size: 40px;
    }

    p {
      font-size: 20px;
    }
  }
}
</style>
