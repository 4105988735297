import { defineNuxtPlugin } from '#app/nuxt'
import { LazyFAQPrimary, LazyMainFAQ, LazyMainFind, LazyMainHero, LazyMainHot, LazyMainInvestment, LazyMainNewOffers, LazyMainPartners, LazyMainTrending, LazyAdminSearchCardEstateUnit, LazyAdminSearchCardLandUnit, LazyBaseButton, LazyBaseCard, LazyBaseComingSoon, LazyBaseCookiePopUp, LazyBaseCountryPhoto, LazyBaseCustomSelectAcreage, LazyBaseCustomSelectCountry, LazyBaseCustomSelect, LazyBaseCustomSelectStage, LazyBaseCustomSelectToken, LazyBaseCustomSelectTypes, LazyBaseErrorBackdrop, LazyBaseFaq, LazyBaseFavoriteAdd, LazyBaseFieldBlocker, LazyBaseFilesUploaderImgPreview, LazyBaseFilesUploader, LazyBaseFooter, LazyBaseGallery, LazyBaseGetInTouch, LazyBaseHeader, LazyBaseIcon, LazyBaseInputButton, LazyBaseLanguageButton, LazyBaseLoader, LazyBaseLogoLoader, LazyBaseModalСonfirmation, LazyBaseNavigatorFiltersCheckbox, LazyBaseNavigatorFiltersInputRange, LazyBaseNavigatorFiltersMultyButts, LazyBaseNavigator, LazyBaseNavigatorTypes, LazyBasePageLoader, LazyBasePageTabs, LazyBasePaginator, LazyBasePriceRangeSlider, LazyBaseProgressBar, LazyBasePropertyInfo, LazyBaseSearchCard, LazyBaseSearchCardNew, LazyBaseSearchCardSoldOutTag, LazyBaseSearchCardVisitors, LazyBaseSearch, LazyBaseSeparator, LazyBaseSubscribe, LazyBaseSuggestedEstates, LazyBaseTag, LazyBaseTooltip, LazyBaseUnitTimeline, LazyCalculator, LazyComparisonMapBlock, LazyFavoriteFavoritePopUp, LazyItemEditorAdminUnitCommon, LazyItemEditorAdminUnitEstate, LazyItemEditorAdminUnitLand, LazyItemEditor, LazyItemEditorSelectType, LazyLoginLoginPopUp, LazyLoginRestorePopUp, LazyMapStatic, LazyMobileSwiperGallery, LazyPhotoWall, LazyProfileMobileUserSettingsPopUp, LazyProfileUserSettingsPopUp, LazySearchMobileFilter, LazySearchPageNoObjects, LazySwiperGallery, LazySwiperSlider, LazyUnitPageCompareBlock, LazyUnitPageEstateUnit, LazyUnitPageFinanceBlockEstate, LazyUnitPageFinanceBlockLand, LazyUnitPageInfoBlock, LazyUnitPageLandUnit, LazyUnitPageTypes, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["FAQPrimary", LazyFAQPrimary],
["MainFAQ", LazyMainFAQ],
["MainFind", LazyMainFind],
["MainHero", LazyMainHero],
["MainHot", LazyMainHot],
["MainInvestment", LazyMainInvestment],
["MainNewOffers", LazyMainNewOffers],
["MainPartners", LazyMainPartners],
["MainTrending", LazyMainTrending],
["AdminSearchCardEstateUnit", LazyAdminSearchCardEstateUnit],
["AdminSearchCardLandUnit", LazyAdminSearchCardLandUnit],
["BaseButton", LazyBaseButton],
["BaseCard", LazyBaseCard],
["BaseComingSoon", LazyBaseComingSoon],
["BaseCookiePopUp", LazyBaseCookiePopUp],
["BaseCountryPhoto", LazyBaseCountryPhoto],
["BaseCustomSelectAcreage", LazyBaseCustomSelectAcreage],
["BaseCustomSelectCountry", LazyBaseCustomSelectCountry],
["BaseCustomSelect", LazyBaseCustomSelect],
["BaseCustomSelectStage", LazyBaseCustomSelectStage],
["BaseCustomSelectToken", LazyBaseCustomSelectToken],
["BaseCustomSelectTypes", LazyBaseCustomSelectTypes],
["BaseErrorBackdrop", LazyBaseErrorBackdrop],
["BaseFaq", LazyBaseFaq],
["BaseFavoriteAdd", LazyBaseFavoriteAdd],
["BaseFieldBlocker", LazyBaseFieldBlocker],
["BaseFilesUploaderImgPreview", LazyBaseFilesUploaderImgPreview],
["BaseFilesUploader", LazyBaseFilesUploader],
["BaseFooter", LazyBaseFooter],
["BaseGallery", LazyBaseGallery],
["BaseGetInTouch", LazyBaseGetInTouch],
["BaseHeader", LazyBaseHeader],
["BaseIcon", LazyBaseIcon],
["BaseInputButton", LazyBaseInputButton],
["BaseLanguageButton", LazyBaseLanguageButton],
["BaseLoader", LazyBaseLoader],
["BaseLogoLoader", LazyBaseLogoLoader],
["BaseModalСonfirmation", LazyBaseModalСonfirmation],
["BaseNavigatorFiltersCheckbox", LazyBaseNavigatorFiltersCheckbox],
["BaseNavigatorFiltersInputRange", LazyBaseNavigatorFiltersInputRange],
["BaseNavigatorFiltersMultyButts", LazyBaseNavigatorFiltersMultyButts],
["BaseNavigator", LazyBaseNavigator],
["BaseNavigatorTypes", LazyBaseNavigatorTypes],
["BasePageLoader", LazyBasePageLoader],
["BasePageTabs", LazyBasePageTabs],
["BasePaginator", LazyBasePaginator],
["BasePriceRangeSlider", LazyBasePriceRangeSlider],
["BaseProgressBar", LazyBaseProgressBar],
["BasePropertyInfo", LazyBasePropertyInfo],
["BaseSearchCard", LazyBaseSearchCard],
["BaseSearchCardNew", LazyBaseSearchCardNew],
["BaseSearchCardSoldOutTag", LazyBaseSearchCardSoldOutTag],
["BaseSearchCardVisitors", LazyBaseSearchCardVisitors],
["BaseSearch", LazyBaseSearch],
["BaseSeparator", LazyBaseSeparator],
["BaseSubscribe", LazyBaseSubscribe],
["BaseSuggestedEstates", LazyBaseSuggestedEstates],
["BaseTag", LazyBaseTag],
["BaseTooltip", LazyBaseTooltip],
["BaseUnitTimeline", LazyBaseUnitTimeline],
["Calculator", LazyCalculator],
["ComparisonMapBlock", LazyComparisonMapBlock],
["FavoriteFavoritePopUp", LazyFavoriteFavoritePopUp],
["ItemEditorAdminUnitCommon", LazyItemEditorAdminUnitCommon],
["ItemEditorAdminUnitEstate", LazyItemEditorAdminUnitEstate],
["ItemEditorAdminUnitLand", LazyItemEditorAdminUnitLand],
["ItemEditor", LazyItemEditor],
["ItemEditorSelectType", LazyItemEditorSelectType],
["LoginLoginPopUp", LazyLoginLoginPopUp],
["LoginRestorePopUp", LazyLoginRestorePopUp],
["MapStatic", LazyMapStatic],
["MobileSwiperGallery", LazyMobileSwiperGallery],
["PhotoWall", LazyPhotoWall],
["ProfileMobileUserSettingsPopUp", LazyProfileMobileUserSettingsPopUp],
["ProfileUserSettingsPopUp", LazyProfileUserSettingsPopUp],
["SearchMobileFilter", LazySearchMobileFilter],
["SearchPageNoObjects", LazySearchPageNoObjects],
["SwiperGallery", LazySwiperGallery],
["SwiperSlider", LazySwiperSlider],
["UnitPageCompareBlock", LazyUnitPageCompareBlock],
["UnitPageEstateUnit", LazyUnitPageEstateUnit],
["UnitPageFinanceBlockEstate", LazyUnitPageFinanceBlockEstate],
["UnitPageFinanceBlockLand", LazyUnitPageFinanceBlockLand],
["UnitPageInfoBlock", LazyUnitPageInfoBlock],
["UnitPageLandUnit", LazyUnitPageLandUnit],
["UnitPageTypes", LazyUnitPageTypes],
["NuxtIcon", LazyNuxtIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
