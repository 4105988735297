<template>
  <div class="coming-soon">
    <img src="~/assets/img/coming-soon/coming-soon.svg" alt="coming-soon" >
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.coming-soon {
  position: absolute;
  z-index: 99999999999999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  border-radius: 10px;
  background: #e9ebf899;
  img {
    width: 70%;
    height: 70%;
  }
}
</style>
