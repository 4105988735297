import {
  getLocalStorage,
  writePersistentLocalStorage,
} from "~/services/LocalStorage/localStorage";
import type {
  LandResponseDTO,
  EstateResponseDTO,
} from "~/services/swagger/Api";
import { Notification } from "~/services/notifications/toast";

export interface ITabs {
  label: string;
  isChecked?: boolean;
  blockerValue?: string[];
  id?: number;
}

export const failSafe = (
  item: LandResponseDTO | EstateResponseDTO,
  display: Ref<number>,
  timeout: Ref<NodeJS.Timeout | undefined>
) => {
  if (timeout) {
    clearInterval(timeout.value);
  }

  display.value = 10;

  timeout.value = setInterval(() => {
    writePersistentLocalStorage("admin-object", item);

    display.value = 10;
  }, 10000);

  setInterval(() => {
    if (display.value > 0) {
      display.value -= 1;
    }
  }, 1000);
};

export const askForFailSafe = (
  item: LandResponseDTO | EstateResponseDTO,
  display: Ref<number>,
  timeout: Ref<NodeJS.Timeout | undefined>
) => {
  const userConfirmed = confirm("Start saving all data (each 10s)?");
  if (userConfirmed) {
    failSafe(item, display, timeout);
  }
};

export const getFailSafeItem = (timeout: Ref<NodeJS.Timeout | undefined>) => {
  const item: LandResponseDTO | EstateResponseDTO | null | undefined =
    getLocalStorage("admin-object");
  if (!item) {
    new Notification({
      type: "system",
      message: "There is nothing saved =(",
    }).createNewToast();
    clearInterval(timeout.value);
    return false;
  }
  clearInterval(timeout.value);
  return item;
};
