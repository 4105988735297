import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__rwhuyq3fg7jim3fzllxdp5tq6i/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_eslint@9.12.0_jiti@2.3.3__ioredis@5.4.1__rwhuyq3fg7jim3fzllxdp5tq6i/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "admin-auth-middleware": () => import("/vercel/path0/middleware/admin-auth-middleware.ts"),
  "auth-middleware": () => import("/vercel/path0/middleware/auth-middleware.ts"),
  "unit-page-middleware": () => import("/vercel/path0/middleware/unit-page-middleware.ts")
}