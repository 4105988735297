<script setup lang="ts"></script>

<template>
  <section class="main-new-offers--wrapper">
    <div class="main-new-offers">
      <h2 class="font--h1 mobile-font--h4 main-new-offers--title">
        <span class="main-new-offers--title-stroke">This Week’s</span>
        <span class="main-new-offers--title-regular">New Offers</span>
      </h2>

      <ul class="card--wrapper">
        <li class="card">
          <div class="card--text">
            <h3 class="font--h3 mobile-font--h5 card--title">Penthouse</h3>
            <div class="card--country">
              <base-icon name="flags/united_arab_emirates" size="24px" filled />
              <p class="font--b1 mobile-font--b2">Dubai</p>
            </div>
            <div class="tags-wrapper">
              <base-tag variant="popular" />
              <base-tag variant="new" />
            </div>
          </div>
          <base-icon name="base/Fire" size="32px" />
          <img
            src="~/assets/img/CardOneMain.png"
            alt=""
            class="card--image card--one"
          >
        </li>

        <li class="card">
          <div class="card--text">
            <h3 class="font--h3 mobile-font--h5 card--title">Condominium</h3>
            <div class="card--country">
              <base-icon name="flags/thailand" size="24px" filled />
              <p class="font--b1 mobile-font--b2">Thailand</p>
            </div>
            <div class="tags-wrapper">
              <base-tag variant="best" />
              <base-tag variant="popular" />
            </div>
          </div>
          <base-icon name="base/Fire" size="32px" />
          <img
            src="~/assets/img/CardTwoMain.png"
            alt=""
            class="card--image card--two"
          >
        </li>

        <li class="card">
          <div class="card--text">
            <h3 class="font--h3 mobile-font--h5 card--title">Coastal Villa</h3>
            <div class="card--country">
              <base-icon name="flags/philippines" size="24px" filled />
              <p class="font--b1 mobile-font--b2">Philippines</p>
            </div>
            <div class="tags-wrapper">
              <base-tag variant="popular" />
              <base-tag variant="new" />
            </div>
          </div>
          <base-icon name="base/Fire" size="32px" />
          <img
            src="~/assets/img/CardThreeMain.png"
            alt=""
            class="card--image card--three"
          >
        </li>
        <nuxt-link to="#" class="main-new-offers--link" target="_blank">
          <base-button text="View All" size="lg" variant="ghost" class="button"
        /></nuxt-link>
      </ul>
    </div>
  </section>
</template>

<style scoped lang="scss">
.main-new-offers {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 140px 80px 94px 80px;

  &--wrapper {
    width: 100dvw;
    min-width: 320px;
    margin: 120px 0 75px;
    background-color: var(--black-monochrome);
  }

  &--title {
    max-width: 432px;
  }

  &--title-stroke {
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
  }

  &--title-regular {
    color: var(--white-monochrome);
    display: block;
    max-width: 300px;
  }

  &--link {
    all: unset;
    margin-top: 14px;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background-color: var(--white-contrast);

  border-radius: 24px;
  padding: 17px 16px;
  width: 704px;
  height: 180px;

  &--wrapper {
    all: unset;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
  }

  &--text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &--image {
    position: absolute;
  }

  &--country {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 9px;
    padding: 9px 0 25px 0;
  }

  .tags-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 5px;
    gap: 13px;
  }

  &--one {
    bottom: -7px;
    right: 134px;
    max-width: 199px;
  }

  &--two {
    bottom: -7px;
    right: 134px;
    max-width: 181px;
  }

  &--three {
    bottom: 6px;
    right: 141px;
    max-width: 194px;
  }
}

@media (max-width: 70rem) {
  .main-new-offers {
    flex-direction: column;
    align-items: center;
    padding: 60px 24px;
    gap: 37px;

    &--link {
      margin-top: 36px;
      width: 100%;
      button {
        width: 100%;
      }
    }
  }

  .card {
    width: 420px;

    &--one {
      max-width: 134px;
      bottom: 13px;
      right: 52px;
    }

    &--two {
      bottom: 23px;
      right: 36px;
      max-width: 121px;
    }

    &--three {
      bottom: -5px;
      right: 43px;
      max-width: 132px;
    }
  }
}

@media (max-width: 32rem) {
  @include mobile-fonts;

  .main-new-offers {
    align-items: flex-start;

    &--wrapper {
      margin: 24px 0 0;
    }

    &--title {
      font-weight: 700;
    }
  }

  .card {
    border-radius: 16px;
    width: 345px;
    height: 140px;

    &--wrapper {
      align-self: center;
    }

    &--country {
      padding: 0 0 16px 0;
    }

    .tags-wrapper {
      .base-tag {
        border-color: inherit;
      }
      gap: 10px;
      padding: 0;
    }
  }
}
</style>
