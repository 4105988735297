import { useScriptHotjar, useScriptGoogleTagManager, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const googleTagManager = useScriptGoogleTagManager({"id":"G-PXLLPJB19H"})
    const hotjar = useScriptHotjar({"id":5060044})
    return { provide: { $scripts: { googleTagManager, hotjar } } }
  }
})