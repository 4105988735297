// import noIcon from "~/public/no-icon.svg";

let timer: ReturnType<typeof setTimeout>;

// export default defineNuxtPlugin((nuxtApp) => {
//   nuxtApp.vueApp.directive("image-error", {
//     mounted(
//       el: HTMLImageElement,
//       binding: {
//         value: {
//           timeout?: number;
//           onImageError?: Function;
//           onImageLoad?: Function;
//         };
//       }
//     ) {
//       console.log('el: ', el);

//       if (el.src.includes("no-icon")) return; // Пропуск, если уже fallback

//       const timeout = binding.value?.timeout || 5000;
//       const onImageError = binding.value?.onImageError;
//       const onImageLoad = binding.value?.onImageLoad;
//       let loaded = false; // Флаг для предотвращения ненужной замены

//       // Проверяем, если изображение уже загружено (для кэшированных изображений)
//       if (el.complete && el.naturalWidth !== 0) {
//         handleLoad(); // Если уже загружено, вызовем onload вручную
//       } else {
//         // Устанавливаем обработчики для обычной загрузки
//         el.onload = handleLoad;
//         el.onerror = handleError;

//         // Таймер на случай задержки загрузки
//         timer = setTimeout(() => {
//           if (!loaded && el.src !== noIcon) {
//             console.warn(`Image load timeout: ${el.src}`);
//             replaceWithFallback();
//             if (onImageError) onImageError();
//           }
//         }, timeout);
//       }

//       // Обработчик успешной загрузки
//       function handleLoad() {
//         if (loaded || el.src.includes("no-icon")) return; // Предотвращаем повторные вызовы
//         loaded = true;
//         clearTimeout(timer); // Останавливаем таймер
//         if (onImageLoad) onImageLoad(el.src);
//         cleanup(); // Убираем обработчики
//       }

//       // Обработчик ошибки загрузки
//       function handleError() {
//         if (el.src.includes("no-icon")) return; // Предотвращаем бесконечный цикл
//         clearTimeout(timer); // Останавливаем таймер
//         replaceWithFallback();
//         if (onImageError) onImageError();
//         cleanup(); // Убираем обработчики
//       }

//       // Замена на fallback-иконку
//       function replaceWithFallback() {
//         if (!loaded) {
//           el.src = noIcon;
//         }
//       }

//       // Убираем обработчики
//       function cleanup() {
//         el.onload = null;
//         el.onerror = null;
//       }
//     },

//     unmounted(el: HTMLImageElement) {
//       // Очищаем таймер и обработчики при размонтировании
//       el.onload = null;
//       el.onerror = null;
//       clearTimeout(timer);
//     },
//   });
// });

import noIcon from "~/public/no-icon.svg";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("image-error", {
    mounted(
      el: HTMLImageElement,
      binding: {
        value: {
          timeout?: number;
          onImageError?: Function;
          onImageLoad?: Function;
        };
      }
    ) {
      const src = el.getAttribute("data-src");
      console.log('src: ', src);

      // Skip if no source provided
      if (!src) {
        el.src = noIcon;
        binding.value?.onImageError?.();
        return;
      }

      el.src = src; // Dynamically set `src` after the directive is initialized

      const timeout = binding.value?.timeout || 5000;
      const onImageError = binding.value?.onImageError;
      const onImageLoad = binding.value?.onImageLoad;
      let loaded = false;

      timer = setTimeout(() => {
        if (!loaded && el.src !== noIcon) {
          console.warn(`Image load timeout: ${el.src}`);
          replaceWithFallback();
          onImageError?.();
        }
      }, timeout);

      el.onload = () => {
        if (loaded || el.src.includes("no-icon")) return;
        loaded = true;
        clearTimeout(timer);
        onImageLoad?.(el.src);
      };

      el.onerror = () => {
        if (el.src.includes("no-icon")) return;
        clearTimeout(timer);
        replaceWithFallback();
        onImageError?.();
      };

      function replaceWithFallback() {
        el.src = noIcon;
      }
    },

    unmounted(el: HTMLImageElement) {
      clearTimeout(timer);
      el.onload = null;
      el.onerror = null;
    },
  });
});
