import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook("vue:error", (error, instance, info) => {
    Sentry.captureException(error);
    if (isCriticalError(error)) {
      sendMessageToBot(error, info);
      showError({
        statusCode: error?.statusCode || 500,
        // statusMessage: error.message || "A critical error occurred",
      });
    }
  });
});

function isCriticalError(error:any) {
  return error.message.includes("Critical") || error.fatal === true;
}

const sendMessageToBot = async (error: Error | any, info: string) => {
  const token = "7387409347:AAFKwQu2KGITZzdenwttkv3C9juNBnk7vGc";
  const chatId = "970758864";

  const url = `https://api.telegram.org/bot${token}/sendMessage`;
  const errorMessage = `
    *Ошибка:*
    ${error.message}
    *Стеки вызовов:*
    ${error.stack || "Стек вызовов не доступен"}
    *Дополнительная информация:*
    ${info}
  `;

  try {
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: chatId,
        text: errorMessage,
      }),
    });
  } catch (error) {
    console.error("Ошибка при отправке сообщения:", error);
  }
};
